import React from 'react';

function useBodyScrollLock(condition = true) {
  React.useLayoutEffect(() => {
    if (!condition) return;

    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;

    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';

    /* eslint-disable consistent-return */
    return () => {
      // Re-enable scrolling when component unmounts
      document.body.style.overflow = originalStyle;
    };
  }, [condition]);
}

export default useBodyScrollLock;
