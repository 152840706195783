import React from 'react';

const BurgerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H32V32H0z" transform="translate(-3 -4)" />
      <path
        fill="#28AAFF"
        d="M3 4H29V6H3zM3 15H29V17H3zM3 26H29V28H3z"
        transform="translate(-3 -4)"
      />
    </g>
  </svg>
);

export default BurgerIcon;
