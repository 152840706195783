import styled from 'styles/styled';
import GhostButton from 'components/Button/GhostButton';
import FilledButton from 'components/Button/FilledButton';

export const Container = styled.header`
  position: relative;
  border-image-width: 0;
  display: flex;
`;

export const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;
  height: 400px;

  @media ${({ theme }) => theme.devices.medium} {
    height: 100vh;
    min-height: 900px;
  }
`;

export const HeaderBackgroundImage = styled.img`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  object-fit: cover;
  height: 400px;

  @media ${({ theme }) => theme.devices.medium} {
    height: 100vh;
    min-height: 900px;
  }

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

export const HeaderVideo = styled.video`
  display: inline-block;
  position: relative;
  left: 100%;
  margin-left: -200%;
  width: 100%;
  object-fit: cover;
  height: 400px;

  @media ${({ theme }) => theme.devices.medium} {
    height: 100vh;
    min-height: 900px;
  }
`;

export const LogoWrapper = styled.a<{ fixedHeader: boolean }>`
  z-index: 10000;
  width: auto;
  max-width: 180px;
  position: ${({ fixedHeader }) => !fixedHeader && 'absolute'};
  top: 16px;
  left: 16px;

  svg {
    width: 100%;
    height: 100%;
  }

  @media ${({ theme }) => theme.devices.medium} and (max-width: ${({ theme }) =>
      theme.deviceSize.large}px) {
    width: 15%;
    top: 45px;
    left: 20px;
    max-width: 180px;
    position: initial;
  }

  @media ${({ theme }) => theme.devices.large} {
    width: 15%;
    top: 45px;
    left: 20px;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    width: 15%;
    top: 45px;
    left: 30px;
  }
`;

export const StartSectionWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.devices.medium} {
    display: block;
  }
`;

export const Wrapper = styled.div<{ isOpen: boolean; fixedHeader: boolean }>`
  display: flex;
  position: ${({ isOpen, fixedHeader }) =>
    isOpen || fixedHeader ? 'fixed' : 'absolute'};
  z-index: 9999;
  left: 0;
  top: 0;

  width: 100%;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.palette.primary : theme.palette.light};

  height: ${({ theme }) => theme.dimensions.menuHeight}px;
  justify-content: space-between;
  padding: ${({ theme }) => theme.dimensions.spacingBase14}px
    ${({ theme }) => theme.dimensions.spacingBase16}px;
  box-shadow: ${({ fixedHeader }) =>
    fixedHeader && '0 4px 30px 0 rgba(0, 0, 0, 0.1)'};

  @media ${({ theme }) => theme.devices.large} {
    position: ${({ fixedHeader }) => (fixedHeader ? 'fixed' : 'absolute')};
    background-color: ${({ fixedHeader, theme }) =>
      fixedHeader ? theme.palette.light : 'transparent'};
    padding: ${({ fixedHeader, theme }) =>
        fixedHeader ? theme.dimensions.basePadding : 44}px
      25px;
    height: auto;

    li {
      a {
        color: ${({ fixedHeader, theme }) =>
          fixedHeader ? '#000' : theme.palette.light};
      }
    }
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    ${({ fixedHeader, theme }) =>
      fixedHeader
        ? `${theme.dimensions.basePadding}px 100px`
        : '44px 100px 44px 55px'};
  }

  @media ${({ theme }) => theme.devices.xxlarge} {
    ${({ fixedHeader, theme }) =>
      fixedHeader
        ? `${theme.dimensions.basePadding}px 150px`
        : '44px 150px 44px 55px'};
  }
`;

export const Menu = styled.nav`
  display: flex;
  align-items: center;
  z-index: 999;
`;

export const MenuItem = styled.li<{ showLanguageSelector?: boolean }>`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  display: ${({ showLanguageSelector }) =>
    showLanguageSelector ? 'none' : 'flex'};
  align-items: center;
  cursor: pointer;
  &:hover {
    a,
    svg {
      opacity: 0.7;
    }
  }

  i {
    padding: 0 8px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    display: flex;
  }
`;

export const MenuLink = styled.a`
  color: #000;

  @media ${({ theme }) => theme.devices.large} {
    color: ${({ theme }) => theme.palette.light};
  }
`;

export const MenuList = styled.ul`
  display: flex;
  ${MenuItem} {
    color: ${({ theme }) => theme.palette.light};
    &:not(:first-child) {
      margin-left: ${({ theme }) => theme.dimensions.spacingBase10 * 2}px;

      @media ${({ theme }) => theme.devices.large} {
        margin-left: ${({ theme }) => theme.dimensions.spacingBase10 * 2}px;
      }

      @media ${({ theme }) => theme.devices.xlarge} {
        margin-left: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
      }

      @media ${({ theme }) => theme.devices.xxlarge} {
        margin-left: ${({ theme }) => theme.dimensions.spacingBase10 * 4}px;
      }
    }
  }
`;

export const BurgerMenu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  height: 100vh;
  position: relative;
  padding-bottom: 150px;

  &:after {
    background-color: #fff;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: -25px;
    z-index: -1;
  }

  ${MenuItem} {
    font-size: ${({ theme }) => theme.fontSize.base};
    margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 2.4}px;
    padding: 8px;
  }
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.dimensions.menuHeight}px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.light};

  @media (max-height: 560px) {
    clip-path: polygon(0 5%, 100% 2%, 100% 100%, 0 100%);
    overflow-y: scroll;
    top: ${({ theme }) => theme.dimensions.menuHeight - 12}px !important;
  }
`;

export const IconsWrapper = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.dimensions.spacingBase16 * 2}px;
    }
  }
`;

export const MenuButton = styled(GhostButton)`
  border-color: ${({ theme }) => theme.palette.light};

  &:hover {
    * {
      opacity: 1;
    }
    div {
      background-color: ${({ theme, fixedHeader }) =>
        fixedHeader ? theme.palette.primary : theme.palette.light};
    }
    a {
      color: ${({ theme, fixedHeader }) =>
        fixedHeader ? theme.palette.light : theme.palette.primary};
    }

    &::after {
      background-color: ${({ theme }) => theme.palette.light};
      right: 5px;
      width: 11px;
    }
  }

  div,
  &::after {
    border-color: ${({ theme }) => theme.palette.light};
  }
`;

export const OpenMenuButton = styled(FilledButton)`
  a {
    color: ${({ theme }) => theme.palette.light};
  }
`;
