import React from 'react';
import { theme } from 'styles/styled';

const ChevronIcon = ({ color = theme.palette.light }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="8"
    viewBox="0 0 12 8">
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M9.713 6L8.308 7.41 12.872 12 8.308 16.59 9.713 18 15.692 12z"
        transform="rotate(90 13 5)"
      />
      <path d="M0 0L24 0 24 24 0 24z" transform="rotate(90 13 5)" />
    </g>
  </svg>
);

export default ChevronIcon;
