import React, { useEffect } from 'react';

import { theme } from 'styles/styled';
import MainLogo from 'images/Logo';

import useLinkCreator from 'hooks/useLinkCreator';
import useWindowSize from 'hooks/useWindowSize';
import useBodyScrollLock from 'hooks/useBodyScrollLock';
import useToggle from 'hooks/useToggle';
import CloseIcon from 'images/Icons/CloseIcon';
import BurgerIcon from 'images/Icons/BurgerIcon';
import menuLinks from 'settings/menuLinks';

import TransparentButton from 'components/Button/TransparentButton';
import Triangle, { CornerTypes } from 'components/Triangle/Triangle';
import {
  Container,
  Menu,
  BurgerMenu,
  AbsoluteWrapper,
} from 'components/Header/styled';

import {
  EduWrapper,
  Navigation,
  OrnamentTriangle,
  Navbar,
  LogoPlaceholder,
  MenuItems,
  MobileMenuOrnamentTriangleBottom,
  EducationMenuList,
  HomepageLink,
} from './styled';

const EducationNavigation = () => {
  const mobileMenuBreakpoint = theme.deviceSize.xlarge;

  const { width } = useWindowSize();
  const isMobile = width < mobileMenuBreakpoint;

  const createLink = useLinkCreator();

  const { on: isMenuOpen, toggle: toggleMenu, setOff: closeMenu } = useToggle();

  useBodyScrollLock(isMenuOpen);

  useEffect(() => {
    if (width >= mobileMenuBreakpoint) {
      closeMenu();
    }
  }, [width, closeMenu, mobileMenuBreakpoint]);

  return (
    <Navigation>
      <HomepageLink
        to={createLink(menuLinks.home)}
        aria-label="Mining City Logo - homepage link">
        <MainLogo
          color={isMenuOpen ? theme.palette.light : theme.palette.dark}
        />
      </HomepageLink>
      <LogoPlaceholder isMenuOpen={isMenuOpen} />
      <OrnamentTriangle />
      <Navbar isMenuOpen={isMenuOpen} />
      <Container as="div">
        <EduWrapper isOpen={isMenuOpen} fixedHeader>
          <Menu as="div">
            {isMobile && (
              <TransparentButton
                onClick={toggleMenu}
                aria-label="Toggle mobile menu">
                {isMenuOpen && <CloseIcon />}
                {!isMenuOpen && <BurgerIcon />}
              </TransparentButton>
            )}
            {!isMobile && (
              <EducationMenuList>
                <MenuItems
                  toggleMenu={toggleMenu}
                  fixedHeader
                  isMenuOpen={isMenuOpen}
                />
              </EducationMenuList>
            )}
            {isMenuOpen && (
              <AbsoluteWrapper style={{ top: '92px' }}>
                <BurgerMenu>
                  <Triangle
                    cornerType={CornerTypes['top-left']}
                    showOnMobile
                    width="100%"
                    height="25px"
                    color={theme.palette.primary}
                    zIndex={100}
                    position={{
                      top: '-25px',
                      left: 0,
                    }}
                  />
                  <MenuItems
                    fixedHeader
                    isMenuOpen={isMenuOpen}
                    toggleMenu={toggleMenu}
                  />
                  <MobileMenuOrnamentTriangleBottom />
                </BurgerMenu>
              </AbsoluteWrapper>
            )}
          </Menu>
        </EduWrapper>
      </Container>
    </Navigation>
  );
};

export default EducationNavigation;
