import styled from 'styled-components';
import { theme } from 'styles/styled';
import ResponsiveButtonBase, { Skew } from './styled';

const SkewToClipPath: Record<Skew, string> = {
  [Skew['top-right']]: 'polygon(0 0, 92% 0, 100% 100%, 0% 100%)',
  [Skew['bottom-right']]: 'polygon(0 0, 100% 0, 92% 100%, 0% 100%)',
  [Skew['top-left']]: 'polygon(8% 0, 100% 0, 100% 100%, 0% 100%);',
  [Skew['bottom-left']]: 'polygon(0 0, 100% 0, 100% 100%, 8% 100%)',
  [Skew['none']]: 'none',
};

interface FilledButtonProps {
  skew: Skew;
  isRtl: boolean;
}

const FilledButton = styled(ResponsiveButtonBase)<
  FilledButtonProps & React.PropsWithoutRef<JSX.IntrinsicElements['button']>
>`
  background: ${theme.palette.primary};
  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};

  color: ${theme.palette.light};
  padding: ${theme.dimensions.spacingBase16}px 46px
    ${theme.dimensions.spacingBase16}px 40px;
  text-transform: uppercase;
  outline: none;

  clip-path: ${({ skew }) => SkewToClipPath[skew]};

  :disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;

export { Skew } from './styled';
export default FilledButton;
