import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import GhostButton, { Skew } from 'components/Button/GhostButton';
import styled, { theme } from 'styles/styled';
import useCurrentLocale, { useIsRtl } from 'hooks/useCurrentLocale';

import {
  useTranslations,
  generateLinkToTheNewLocale,
  useLanguages,
} from 'settings/i18n';
import {
  Wrapper,
  MenuList,
  MenuLink,
  MenuItem,
} from 'components/Header/styled';
import { useLocation } from 'hooks/useLocation';
import ChevronIcon from 'images/Icons/ChevronIcon';
import LanguageIcon from 'images/Icons/LanguageIcon';
import useWindowSize from 'hooks/useWindowSize';
import useToggle from 'hooks/useToggle';
import useLinkCreator from 'hooks/useLinkCreator';
import menuLinks from 'settings/menuLinks';

import {
  Container as LanguageSelectorContainer,
  ChevronWrapper,
  ListItem,
  ListWrapper,
  SelectedValueWrapper,
  ListItemLink,
} from '../LanguageSelector/styles';

export const LogoPlaceholder = styled.div<{ isMenuOpen: boolean }>`
  z-index: 3;
  position: fixed;
  background: ${(props) =>
    props.isMenuOpen ? theme.palette.primary : theme.palette.light};
  padding: ${theme.dimensions.spacingBase16}px 0 0
    ${theme.dimensions.spacingBase16}px;
  clip-path: polygon(0 0, 100% 0, 100% 81%, 0% 100%);
  height: 85px;
  width: 100%;

  svg {
    height: 36px;
    width: 120px;
  }

  @media ${theme.devices.xxlarge} {
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    height: 160px;
    width: 550px;
    padding: 30px 0 0 30px;

    svg {
      height: 55px;
      width: 172px;
    }
  }
`;

export const Navbar = styled.div<{ isMenuOpen: boolean }>`
  z-index: 2;
  position: fixed;
  background: ${theme.palette.primary};
  height: 110px;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 87%);
  ${({ isMenuOpen }) =>
    isMenuOpen ? { display: 'none' } : { display: 'block' }}

  @media ${theme.devices.xxlarge} {
    height: 160px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
  }
`;

export const OrnamentTriangle = styled.div`
  display: none;
  z-index: 1;
  position: fixed;
  height: 172px;
  width: 450px;
  background: ${theme.palette.secondaryLight};
  clip-path: polygon(0 0, 0% 100%, 100% 50%);

  @media ${theme.devices.xxlarge} {
    display: block;
  }
`;

export const Navigation = styled.nav`
  z-index: 102;
  position: absolute;
  top: 0;
`;

export const EduWrapper = styled(Wrapper)`
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  box-shadow: none;
  background: transparent;
  max-width: 1360px;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: ${theme.dimensions.menuHeight}px;

  padding: ${theme.dimensions.spacingBase14}px
    ${theme.dimensions.spacingBase16}px;

  @media ${theme.devices.xxlarge} {
    padding: 30px;
    height: auto;
  }

  @media ${theme.devices.xxlarge} {
    padding: 30px 0;
  }
`;

export const EduGhostButton = styled(GhostButton)`
  background: ${theme.palette.primary};
  border-color: ${theme.palette.light};
  color: ${theme.palette.light};

  > * {
    ${({ skew }) =>
      skew === Skew['bottom-right']
        ? `border-bottom: ${theme.ui.borderMedium(theme.palette.light)};`
        : `border-top: ${theme.ui.borderMedium(theme.palette.light)};`}
  }

  ::after {
    border-left: 2px solid ${theme.palette.light};
    background: white;
  }

  @media ${theme.devices.xxlarge} {
    background: ${theme.palette.white};

    ::after {
      background: transparent;
    }
  }
`;

export interface MenuItemsProps {
  fixedHeader: boolean;
  isMenuOpen: boolean;
  toggleMenu: () => void;
}

export const EduMenuLink = styled(AnchorLink)`
  color: black !important;
  text-decoration: none !important;
  height: 100%;
  display: flex;
  align-items: center;

  @media ${theme.devices.xxlarge} {
    color: white !important;
  }
`;

export const EduSignInMenuLink = styled(MenuLink)`
  color: white !important;
  outline: none;

  @media ${theme.devices.xxlarge} {
    color: white !important;
  }
`;

const EducSelectedValueWrapper = styled(SelectedValueWrapper)`
  height: 10%;
  top: 120px;
`;

interface LanguageSelectorProps {
  isMenuOpen: boolean;
  showLanguageSelector: boolean;
  toggleLanguageSelector: () => void;
}

export const LanguageSelector = ({
  isMenuOpen,
  showLanguageSelector,
  toggleLanguageSelector,
}: LanguageSelectorProps) => {
  const languages = useLanguages();
  const location = useLocation();
  const currentLocale = useCurrentLocale();
  const { width } = useWindowSize();

  const isMobile = width < theme.deviceSize.xxlarge;

  const selectedLang = (mobile: boolean) => (
    <>
      {mobile && (
        <ChevronWrapper
          style={{
            transform: isMenuOpen ? 'rotateZ(90deg)' : 'none',
          }}
          isOpen={showLanguageSelector}>
          <ChevronIcon color={theme.palette[isMobile ? 'primary' : 'light']} />
        </ChevronWrapper>
      )}
      <i>
        <LanguageIcon color={theme.palette[isMobile ? 'primary' : 'light']} />
      </i>

      {languages.find((language) => language.locale === currentLocale)?.name}

      {!mobile && (
        <ChevronWrapper isOpen={showLanguageSelector}>
          <ChevronIcon color={theme.palette[isMobile ? 'primary' : 'light']} />
        </ChevronWrapper>
      )}
    </>
  );

  const isMobileAndOpen = isMobile && isMenuOpen;

  return (
    <LanguageSelectorContainer
      fixedHeader
      onClick={toggleLanguageSelector}
      style={{ color: isMobile ? 'black' : 'white' }}>
      {selectedLang(false)}
      {showLanguageSelector && (
        <ListWrapper isMenuOpen={isMenuOpen}>
          {isMobileAndOpen && (
            <EducSelectedValueWrapper isMobileAndOpen={isMobileAndOpen}>
              {selectedLang(true)}
            </EducSelectedValueWrapper>
          )}
          {languages.map((language) => (
            <React.Fragment key={language.locale}>
              {language.locale !== currentLocale && (
                <ListItem>
                  <ListItemLink
                    onClick={() =>
                      localStorage.setItem('language', language.locale)
                    }
                    to={generateLinkToTheNewLocale(
                      currentLocale,
                      language.locale,
                      location,
                    )}>
                    {language.name}
                  </ListItemLink>
                </ListItem>
              )}
            </React.Fragment>
          ))}
        </ListWrapper>
      )}
    </LanguageSelectorContainer>
  );
};

export const MenuItems = (props: MenuItemsProps) => {
  const t = useTranslations();
  const createLink = useLinkCreator();
  const {
    on: showLanguageSelector,
    toggle: toggleLanguageSelector,
  } = useToggle();
  const closeMobileMenuOnAnchorClick = () => {
    if (props.isMenuOpen) {
      props.toggleMenu();
    }
  };

  const isRtl = useIsRtl();

  return (
    <>
      <MenuItem
        onClick={closeMobileMenuOnAnchorClick}
        showLanguageSelector={showLanguageSelector}>
        <EduMenuLink to={createLink(menuLinks.aboutUs)}>
          {t('aboutUs')}
        </EduMenuLink>
      </MenuItem>
      <MenuItem
        onClick={closeMobileMenuOnAnchorClick}
        showLanguageSelector={showLanguageSelector}>
        <EduMenuLink to={createLink(menuLinks.howTo)}>{t('howTo')}</EduMenuLink>
      </MenuItem>
      <MenuItem
        onClick={closeMobileMenuOnAnchorClick}
        showLanguageSelector={showLanguageSelector}>
        <EduMenuLink to={createLink(menuLinks.events)}>
          {t('events')}
        </EduMenuLink>
      </MenuItem>
      <MenuItem showLanguageSelector={showLanguageSelector}>
        <EduMenuLink to={createLink(menuLinks.education)}>
          {t('education')}
        </EduMenuLink>
      </MenuItem>
      <MenuItem showLanguageSelector={showLanguageSelector}>
        <EduMenuLink to={createLink(menuLinks.blog)}>
          {t('blog')}
        </EduMenuLink>
      </MenuItem>
      <MenuItem showLanguageSelector={showLanguageSelector}>
        <EduMenuLink to={createLink(t('webinarsLink'))}>
          {t('webinarsTitle')}
        </EduMenuLink>
      </MenuItem>
      <MenuItem showLanguageSelector={showLanguageSelector}>
        <EduMenuLink
          id="sign-in-button"
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={createLink(menuLinks.signIn)}>
          {t('signIn')}
        </EduMenuLink>
      </MenuItem>
      <MenuItem showLanguageSelector={showLanguageSelector}>
        <EduSignInMenuLink
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={createLink(menuLinks.signUp)}>
          <EduGhostButton isRtl={isRtl} skew={Skew['bottom-right']}>
            {t('signUp')}
          </EduGhostButton>
        </EduSignInMenuLink>
      </MenuItem>
      <MenuItem>
        <LanguageSelector
          {...props}
          showLanguageSelector={showLanguageSelector}
          toggleLanguageSelector={toggleLanguageSelector}
        />
      </MenuItem>
    </>
  );
};

export const MobileMenuOrnamentTriangleBottom = styled.div`
  height: 56px;
  width: 100%;
  right: -1px;
  clip-path: polygon(0 94%, 100% 2%, 100% 100%, 0 100%);
  bottom: 92px;
  position: absolute;
  background: ${theme.palette.primary};

  @media (max-height: 560px) {
    bottom: 48px;
  }
`;

export const EducationMenuList = styled(MenuList)`
  ${MenuItem} {
    color: ${theme.palette.light};
    &:not(:first-child) {
      margin-left: ${theme.dimensions.spacingBase10 * 2}px;
    }
  }

  @media ${theme.devices.xxlarge} {
    ${MenuItem} {
      &:not(:first-child) {
        margin-left: ${theme.dimensions.spacingBase10 * 2.4}px;
      }
    }
  }
`;

export const HomepageLink = styled(Link)`
  position: fixed;
  z-index: 116;
  top: 20px;
  left: 20px;

  svg {
    height: 36px;
    width: 120px;
  }

  @media ${theme.devices.xxlarge} {
    top: 30px;
    left: 30px;

    svg {
      height: 55px;
      width: 172px;
    }
  }
`;
