import React from 'react';
import { theme } from 'styles/styled';

const LanguageIcon = ({ color = theme.palette.light }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0L23 0 23 23 0 23z" transform="translate(-1 -1)" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M11.49 1.917c-5.29 0-9.573 4.293-9.573 9.583s4.283 9.583 9.573 9.583c5.3 0 9.593-4.293 9.593-9.583S16.79 1.917 11.49 1.917zm6.642 5.75h-2.827a14.997 14.997 0 00-1.323-3.412 7.695 7.695 0 014.15 3.412zM11.5 3.872a13.5 13.5 0 011.83 3.795H9.67a13.5 13.5 0 011.83-3.795zm-7.418 9.545a7.898 7.898 0 01-.249-1.917c0-.661.096-1.303.25-1.917h3.239a15.827 15.827 0 00-.135 1.917c0 .652.058 1.284.135 1.917h-3.24zm.786 1.916h2.827a14.997 14.997 0 001.323 3.412 7.654 7.654 0 01-4.15-3.412zm2.827-7.666H4.868a7.654 7.654 0 014.15-3.412 14.997 14.997 0 00-1.323 3.412zM11.5 19.128a13.5 13.5 0 01-1.83-3.795h3.66a13.5 13.5 0 01-1.83 3.795zm2.242-5.711H9.258a14.1 14.1 0 01-.153-1.917c0-.652.067-1.294.154-1.917h4.485c.086.623.153 1.265.153 1.917a14.1 14.1 0 01-.154 1.917zm.24 5.328a14.997 14.997 0 001.323-3.412h2.827a7.695 7.695 0 01-4.15 3.412zm1.696-5.328c.077-.633.134-1.265.134-1.917 0-.652-.057-1.284-.134-1.917h3.24c.153.614.249 1.256.249 1.917s-.096 1.303-.25 1.917h-3.239z"
        transform="translate(-1 -1)"
      />
    </g>
  </svg>
);

export default LanguageIcon;
