import { Link } from 'gatsby';
import styled from 'styles/styled';

export const Container = styled.div<{ fixedHeader: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  ${({ fixedHeader }) => fixedHeader && { color: '#000' }}
`;

export const ListWrapper = styled.ul<{ isMenuOpen: boolean }>`
  ${({ isMenuOpen }) =>
    isMenuOpen
      ? {
          display: 'flex',
          position: 'fixed',
          top: '75px',
          left: 0,
          bottom: '30px',
          right: 0,
          margin: '56px 0 0',
          paddingBottom: '128px',
          paddingTop: '54px',
          minHeight: '385px',
          alignItems: 'center',
          flexDirection: 'column',
        }
      : {
          position: 'absolute',
          top: '36px',
          left: 0,
          boxShadow: '0 4px 30px 0 rgba(0, 0, 0, 0.1)',
          maxHeight: '385px',
        }};

  @media (max-height: 560px) and (min-width: 425px) {
    margin-top: ${({ isMenuOpen }) => isMenuOpen && '0'};
  }

  background-color: ${({ theme }) => theme.palette.light};
  overflow-y: scroll;
`;

export const ListItem = styled.li`
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.lightSecondary};
  }
`;

export const ListItemLink = styled(Link)`
  color: ${({ theme }) => theme.palette.dark} !important;
  padding: ${({ theme }) => theme.dimensions.spacingBase16 * 1.5}px;
  display: inline-block;
  min-width: 200px;
  text-align: ${({ variant }: { variant?: 'mobile' | 'desktop' }) =>
    variant === 'mobile' ? 'center' : 'left'};
  text-decoration: none !important;

  &:not(:last-of-type) {
    padding-bottom: ${({ theme }) => theme.dimensions.spacingBase16}px;
  }
`;

export const ChevronWrapper = styled.i<{ isOpen: boolean }>`
  transform: rotateZ(${({ isOpen }) => (isOpen ? '180deg' : 0)});
`;

export const SelectedValueWrapper = styled.div<{ isMobileAndOpen?: boolean }>`
  ${({ isMobileAndOpen, theme }) =>
    isMobileAndOpen && {
      position: 'fixed',
      left: theme.dimensions.basePadding,
      top: theme.dimensions.menuHeight,
      width: '100%',
      height: '20%',
    }};

  @media (max-height: 560px) and (min-width: 425px) {
    width: ${({ isMobileAndOpen }) => isMobileAndOpen && '20%'};
  }

  display: flex;
  background-color: ${({ theme }) => theme.palette.light};
  z-index: 100;
  align-items: center;
  justify-content: flex-start;
`;
