import React from 'react';

import { theme } from 'styles/styled';

type CloseIconProps = JSX.IntrinsicElements['svg'] & {
  color?: JSX.IntrinsicElements['path']['fill'];
};

const CloseIcon = ({
  color = theme.palette.light,
  ...props
}: CloseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H32V32H0z" transform="translate(-3 -3)" />
      <path
        fill={color}
        d="M27.632 3L29 4.368 17.368 16 29 27.632 27.632 29 16 17.368 4.368 29 3 27.632 14.632 16 3 4.368 4.368 3 16 14.631 27.632 3z"
        transform="translate(-3 -3)"
      />
    </g>
  </svg>
);

export default CloseIcon;
